.paymentMethods {
  img {
    object-fit: contain;
    position: relative !important;
  }
}

.bg {
  img {
    min-width: 24px !important;
    min-height: 24px !important;
    width: 24px !important;
    height: 24px !important;
  }
}
